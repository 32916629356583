<template>
  <div style="position: relative">
    <login-content>
      <div class="logincont">
        <div class="title">注册账号</div>
        <!-- 输入手机号 -->
        <div class="warning-tip">
          <span><svg-icon icon-class="blue-tip"></svg-icon></span>
          <span class="warning-text">该手机号未注册，请完成账号设置</span>
        </div>
        <template>
          <div class="com-input" :class="{ error: errorMsg }">
            <span class="required">*</span>
            <input
              type="text"
              maxlength="24"
              v-model="userName"
              placeholder="请输入用户名"
              @blur="isAccHint = false"
              @focus="isAccHint = true"
              @keyup.enter="confirm"
            />
            <div class="errtip">{{ errorMsg }}</div>
          </div>
          <div class="com-input" :class="{ error: errorEmailMsg }">
            <input
              type="text"
              maxlength="32"
              v-model="email"
              placeholder="请输入邮箱"
              @keyup.enter="confirm"
            />
            <div class="errtip">{{ errorEmailMsg }}</div>
          </div>
          <div class="com-input" :class="{ error: errorPasswordMsg }">
            <span class="required">*</span>
            <input
              :readonly="readonly"
              :type="isPwdShow ? 'text' : 'password'"
              maxlength="16"
              v-model="password"
              placeholder="请输入密码"
              @blur="isPwdHint = false"
              @focus="
                readonly = false;
                isPwdHint = true;
              "
              @keyup.enter="confirm"
            />
            <span class="pwd-switch icon-pos" @click="isPwdShow = !isPwdShow">
              <span class="icon" v-show="!isPwdShow"
                ><svg-icon icon-class="pwd-on"></svg-icon
              ></span>
              <span class="icon" v-show="isPwdShow"
                ><svg-icon icon-class="pwd-off"></svg-icon
              ></span>
            </span>
            <div class="errtip">{{ errorPasswordMsg }}</div>
          </div>
          <div class="check-tip mar-t20" :class="{ checked: checked }">
            <span class="check-icon" @click="checked = !checked"></span>
            <span>我已阅读并接受</span>
            <!-- <router-link class="color-blue" @click.stop to="/protocol/user" target="_blank"
              >用户协议</router-link
            > -->
            <span class="color-blue">用户协议</span>
            <span>和</span>
            <span class="color-blue">隐私政策</span>
            <!-- <router-link class="color-blue" @click.stop to="/protocol/privacy" target="_blank"
              >隐私政策</router-link
            > -->
          </div>
          <button
            class="com-button nomargin"
            @click="confirm"
            @keyup.enter="confirm"
            :class="[isValid2Submit ? 'com-button-primary' : 'com-button-disabled']"
          >
            提交
          </button>
        </template>
      </div>
    </login-content>
    <popup-hint :top="160" v-show="isAccHint">
      <template #hint>
        <div class="hint-block">
          <span class="hint-text"><svg-icon icon-class="check"></svg-icon>长度6-32位字符</span>
          <span class="hint-text"
            ><svg-icon icon-class="check"></svg-icon
            >由字母、数字或字符_@组成，须以字母开头且不能以@结尾</span
          >
        </div>
      </template>
    </popup-hint>
    <popup-hint :top="280" v-show="isPwdHint">
      <template #hint>
        <div class="hint-block">
          <span class="hint-text"><svg-icon icon-class="check"></svg-icon>长度8-16位字符</span>
          <span class="hint-text"
            ><svg-icon icon-class="check"></svg-icon
            >{{ "由字母、数字或字符_!@#$%^&*中至少两种组成（区分大小写）" }}</span
          >
        </div>
      </template>
    </popup-hint>
  </div>
</template>
<script>
import { pattern_user, pattern_psd, pattern_mail } from "@/tool/pattern.js";
import { registerSubmit } from "@/api/user.js";
import { _debounce } from "@/tool";
export default {
  data() {
    this.confirm = _debounce(this.confirmFn, 1000);
    return {
      isPwdShow: false,
      isValid2Submit: false,
      userName: "",
      password: "",
      email: "",
      readonly: true,
      errorMsg: "",
      errorPasswordMsg: "",
      errorEmailMsg: "",
      checked: false,
      userId: "",
      isAccHint: false,
      isPwdHint: false
    };
  },
  mounted() {
    // 接收userId
    this.userId = localStorage.getItem("userId");
    if (!this.userId) {
      this.$router.replace(this.$route.meta.inject ? "/inject/login/phone" : "/login/phone");
    }
  },
  watch: {
    userName: function (val) {
      this.errorMsg = pattern_user.test(val) ? "" : "用户名格式有误";
      if (!val) {
        this.errorMsg = "";
      }
      this.isValid2Submit =
        val &&
        !this.errorMsg &&
        this.password &&
        !this.errorPasswordMsg &&
        (!this.email || (this.email && !this.errorEmailMsg)) &&
        this.checked;
    },
    password: function (val) {
      this.errorPasswordMsg = pattern_psd.test(val) ? "" : "密码格式有误";
      if (!val) {
        this.errorPasswordMsg = "";
      }
      this.isValid2Submit =
        val &&
        !this.errorPasswordMsg &&
        this.userName &&
        !this.errorMsg &&
        (!this.email || (this.email && !this.errorEmailMsg)) &&
        this.checked;
    },
    email: function (val) {
      this.errorEmailMsg = pattern_mail.test(val) ? "" : "邮箱格式有误";
      if (!val) {
        this.errorEmailMsg = "";
      }
      this.isValid2Submit =
        (!val || (val && !this.errorEmailMsg)) &&
        this.userName &&
        !this.errorMsg &&
        this.password &&
        !this.errorPasswordMsg &&
        this.checked;
    },
    checked: function (val) {
      this.isValid2Submit =
        val &&
        this.userName &&
        !this.errorMsg &&
        this.password &&
        !this.errorPasswordMsg &&
        (!this.email || (this.email && !this.errorEmailMsg));
    }
  },
  methods: {
    // 提交
    async confirmFn() {
      if (!this.isValid2Submit) return;
      const result = await registerSubmit({
        email: this.email,
        headImgUrl: "",
        id: this.userId,
        userAccount: this.userName,
        userPassword: this.password
      });
      if (result.success) {
        // this.$message.success("注册成功");
        this.$router.replace(this.$route.meta.inject ? "/inject/login" : "/login");
      } else {
        this.$message.error(result.message);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.icon-pos {
  position: absolute;
  right: 5px;
  bottom: 13px;
}
.warning-tip {
  background-color: @sso-tip;
}
</style>
